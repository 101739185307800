import Vue from 'vue';

// Axios
import axios from 'axios';
import VueAxios from 'vue-axios';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
console.log('process.env.VUE_APP_API_URL', process.env.VUE_APP_API_URL);

const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

Vue.use(VueAxios, axios);

export default {
  root: process.env.VUE_APP_API_URL
};